<template>
  <!-- Home Section Start -->
  <div id="rs-home" class="home style5">
    <div class="container">
      <div class="row">
        <div class="col-lg-7 col-md-8">
          <div class="banner-content z-index-1">
            <div class="icon-seller mb-20">
              <img src="assets/images/logo-company/logo-blue.png" alt="logo">
            </div>
            <h1 class="title">Falcon Tech</h1>
              <h2>We bring creative future to you</h2>
            <p class="desc">
              We bring the best technology and software solutions to our customers
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Home Section End -->
</template>

<script>
export default {
  name: "home-component"
}
</script>

<style lang="less" scoped>

</style>
