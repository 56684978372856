<template>
  <LandingPage/>
</template>

<script>
import LandingPage from './components/landing-page.vue'

export default {
  name: 'App',
  components: {
    LandingPage
  }
}
</script>

<style>
</style>
