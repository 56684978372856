<template>
  <!-- News Section Start -->
  <div id="rs-members" class="rs-members container style6 gray-color pt-60 pb-100 md-pt-80 md-pb-80 md-pl-15 md-pr-15">
    <div class="sec-title5 mb-60 text-center">
      <h2 class="title">Members</h2>
    </div>
    <div class="rs-carousel owl-carousel"
         data-loop="true" data-items="3" data-margin="70" data-autoplay="true"
         data-hoverpause="true" data-autoplay-timeout="2000"
         data-smart-speed="600" data-dots="false" data-nav="false"
         data-nav-speed="false" data-center-mode="false" data-mobile-device="1"
         data-mobile-device-nav="false" data-mobile-device-dots="false"
         data-ipad-device="2" data-ipad-device-nav="false"
         data-ipad-device-dots="false" data-ipad-device2="2"
         data-ipad-device-nav2="false" data-ipad-device-dots2="false"
         data-md-device="3" data-md-device-nav="false"
         data-md-device-dots="true">
      <div  v-for="member in MEMBER" :key="member.name"  class="testi-item">
        <div class="testi-content">
          <div class="images-wrap">
            <img :src="'assets/images/members/' + member.imgAvatar" alt="Images">
          </div>
          <div class="testi-information">
            <div class="testi-name">{{member.name}}</div>
            <span class="testi-title">{{member.role}}</span>
          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- News Section End -->
</template>

<script setup>
const MEMBER = [
  {name: "Mr Linh", role: "HONORARY CHAIRMAN", imgAvatar: "mr-linh.png" },
  {name: "Mr Chau", role: "HONORARY CHAIRMAN", imgAvatar: "mr-chau.png" },
  {name: "Minh Tu", role: "CHAIRMAN", imgAvatar: "minh-tu.jpg" },
  {name: "Duy Do", role: "CEO", imgAvatar: "duy-do.png" },
  {name: "Hang Vu", role: "CO-FOUNDER", imgAvatar: "hang-vu.png" },
  {name: "Khoi Nguyen", role: "CO-FOUNDER", imgAvatar: "viet-khoi.png" },
  {name: "Khanh Dinh", role: "CO-FOUNDER", imgAvatar: "khanh-dinh.png" },
]
</script>

<style lang="less" scoped>
.rs-members{
  &.main-home{
    .testi-item {
      .author-desc {
        background: @white-color;
        padding: 70px 40px 80px 40px;
        border-radius: 10px 10px 10px 10px;
        box-shadow: -2px 16px 44px -32px rgba(0, 0, 0, 0);
        .desc {
          text-align: center;
          position: relative;
          color: #333333;
          padding: 61px 0px 10px 0px;
          font-size: 17px;
          line-height: 33px;
          font-weight: 400;
          .quote {
            width: 50px;
            top: -13px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
          }
        }
        .author-img {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 80px;
          img {
            width: 90px;
            height: 90px;
            border-radius: 50%;
          }
        }
      }
      .author-part {
        text-align: center;
        padding-top: 70px;
        .name {
          color: #0A0A0A;
          font-size: 22px;
          font-weight: 600;
          display: block;
          margin-bottom: 5px;
        }
        .designation {

        }
      }
    }
    .owl-dots {
      text-align: center;
      margin-top: 47px;
      .owl-dot{
        width: 25px;
        height: 8px;
        display: inline-block;
        margin: 0 6px;
        padding: 3px 0;
        border-radius: 30px;
        border: none;
        transition: @transition;
        background: #0B70E1;
        box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.25);
        &:hover,
        &.active {
          width: 40px;
          background: #0B70E1;
          opacity: 1;
        }
      }
    }
    .owl-stage-outer {
      padding: 45px 0;
      margin: -45px 0;
    }
  }
  &.style6{
    .testi-item{
      background-color: #fff;
      border-radius: 10px 10px 10px 10px;
      box-shadow: 0px 0px 10px 0px rgba(231.74999999999997, 231.74999999999997, 231.74999999999997, 0.5);
      padding: 30px 0;
      margin: 0 -15px 0 0;
      .testi-content{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 25px 0;
        .images-wrap{
          width: 90px;
          height: 90px;
          img{
            border-radius: 50px 50px 50px 50px;
          }
        }
        .testi-information{
          padding: 0 0 0 25px;
          text-align: left;
          .testi-name{
            color: #00152B;
            font-size: 20px;
            font-weight: 600;
            line-height: 36px;
          }
          .testi-title{
            color: #404A50;
            padding-bottom: 10px;
            display: block;
          }
          .rating{
            img{
              width: 100px;
            }
          }
        }
      }
      .item-content-basic{
        padding: 0px 40px 0px 40px;
        text-align: center;
        p{
          color: #272525;
          font-size: 18px;
          font-weight: 500;
          font-style: italic;
          line-height: 35px;
        }
        span{
          img{
            width: 32px;
            top: 82%;
            position: absolute;
            left: 47%;
            z-index: 9;
          }
        }
      }
    }
    .owl-dots {
      text-align: center;
      margin-top: 47px;
      .owl-dot{
        width: 25px;
        height: 8px;
        display: inline-block;
        margin: 0 6px;
        padding: 3px 0;
        border-radius: 30px;
        border: none;
        transition: @transition;
        background: #032390;
        box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.25);
        &:hover,
        &.active {
          width: 40px;
          background: #032390;
          opacity: 1;
        }
      }
    }
  }
}
</style>
