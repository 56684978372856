<template>
  <footer id="rs-footer" class="rs-footer">
    <div class="footer-top">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-12 col-sm-12 footer-widget">
            <div class="footer-logo mb-30">
              <a href="index.html"><img src="assets/images/logo-company/logo-white.png" alt=""></a>
            </div>
            <div class="text-widget pb-30"><p>Contact us when you have questions that need us to answer.</p>
            </div>
            <ul class="footer-social md-mb-30">
              <li>
                <a href="#" target="_blank"><span><i class="fa fa-facebook"></i></span></a>
              </li>
              <li>
                <a href="#" target="_blank"><span><i class="fa fa-twitter"></i></span></a>
              </li>

              <li>
                <a href="#" target="_blank"><span><i class="fa fa-pinterest-p"></i></span></a>
              </li>
              <li>
                <a href="#" target="_blank"><span><i class="fa fa-instagram"></i></span></a>
              </li>

            </ul>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12 pl-45 md-pl-15 md-mb-30">
            <h3 class="widget-title">IT Services</h3>
            <ul class="site-map">
              <li>Software Development</li>
              <li>Web Development</li>
              <li>Analytic Solutions</li>
              <li>Cloud and DevOps</li>
              <li>Product Design</li>
            </ul>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12 md-mb-30">
            <h3 class="widget-title">Contact Info</h3>
            <ul class="address-widget">
              <li>
                <i class="flaticon-location"></i>
                <div class="desc">
                  <a>
                    CIC Tower, 2/219 Trung Kinh, Yen Hoa, Cau Giay, Hanoi, Vietnam
                  </a>
                </div>
              </li>
              <li>
                <i class="flaticon-call"></i>
                <div class="desc">
                  <a href="tel:(+84) 971 990 314">(+84) 971 990 314</a>
                </div>
              </li>
              <li>
                <i class="flaticon-email"></i>
                <div class="desc">
                  <a href="mailto:support@rstheme.com">falcontech.vn@gmail.com</a>
                </div>
              </li>
              <li>
                <i class="flaticon-clock-1"></i>
                <div class="desc">
                  <a>
                    Opening Hours: 09:00 - 18:00
                  </a>
                </div>
              </li>
            </ul>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12">
            <h3 class="widget-title">Newsletter</h3>
            <p class="widget-desc">We denounce with righteous and in and dislike men who are so beguiled and demo realized.</p>
            <p>
              <input type="email" name="EMAIL" placeholder="Your email address" required="">
              <em class="paper-plane"><input type="submit" value="Sign up"></em>
              <i class="flaticon-send"></i>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="container">
        <div class="row y-middle">
          <div class="col-lg-6 text-right md-mb-10 order-last">
            <ul class="copy-right-menu">
              <li><a href="index.html">Home</a></li>
              <li><a href="about.html">About</a></li>
              <li><a href="blog.html">Blog</a></li>
              <li><a href="shop.html">Shop</a></li>
              <li><a href="faq.html">FAQs</a></li>
            </ul>
          </div>
          <div class="col-lg-6">
            <div class="copyright">
              <p>&copy; 2023 All Rights Reserved. Developed By Falcon Tech</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>



<script>
export default {
  name: "footer-component"
}
</script>

<style lang="less" scoped>
.rs-footer{
  //background: url(assets/images/bg/footer-bg.png);
  background-color: #172a3d;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  .text-widget{
    color: @white-color;
  }
  .widget-desc {
    color: @white-color;
  }
  .footer-top{
    padding: 92px 0 65px;
    .footer-logo{
      img{
        height: 40px;
      }
    }
    .widget-title{
      font-size: 22px;
      line-height: 40px;
      font-weight: 600;
      color: @white-color;
      margin-bottom: 25px;
    }
    .site-map{
      li{

        margin-bottom: 12px;
          font-size: 16px;
          color: @white-color;
          &:hover{
            color: @primary-color;
        }
      }
    }
    .footer-social {
      li {
        display: inline;
        margin-right: 5px;
        a {
          display: inline-block;
          width: 35px;
          height: 35px;
          line-height: 35px;
          border-radius: 50%;
          color: #555;
          text-align: center;
          font-size: 15px;
          transition: all .8s ease;
          background: rgba(255, 255, 255, 0.9);
          i {
            line-height: 35px;
          }
          &:hover {
            color: @white-color;
            background: @primary-color;
          }
        }
        &:last-child {
          margin: 0;
        }
      }
    }
    .address-widget{
      li{
        padding: 0px 0px 16px 40px;
        position: relative;
        .desc{
          a{
            color: @white-color;
            &:hover {
              color: @primary-color;
            }

          }
        }
        i{
          color: #fff;
          border-radius: 50px;
          padding: 2px 6px;
          background-color: transparent;
          background-image: linear-gradient(180deg, #00FFFF 0%, #0080FF 79%);
          position: absolute;
          left: 0;
          &:before{
            font-size: 20px;
          }
        }
      }
    }
    .widget-desc{
      margin: 0;
    }
    p{
      margin: 30px 0 0;
      position: relative;
      input[type=email]{
        border: none;
        width: 91%;
        font-size: 13px;
        padding: 16px 60px 16px 20px;
        margin: 0;
        color: #0a0a0a;
        overflow: hidden;
        border-radius: 30px 0 0 30px;
        background: #fff;
        box-shadow: 0 0 25px #eee
      }
      input[type="submit"]{
        position: absolute;
        top: 1px;
        right: 1px;
        bottom: 1px;
        background-image: linear-gradient(180deg, #00FFFF 0%, #0080FF 79%);
        border-radius: 50%;
        padding: 15px 28px 15px;
        font-size: 0;
        border: none;
      }
      i{
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        padding: 12px 20px;
        pointer-events: none;
        color: @white-color;
      }

      ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: @body-color;
        opacity: 1;
      }
      ::-moz-placeholder { /* Firefox 19+ */
        color: @body-color;
        opacity: 1;
      }
      :-ms-input-placeholder { /* IE 10+ */
        color: @body-color;
        opacity: 1;
      }
      :-moz-placeholder { /* Firefox 18- */
        color: @body-color;
        opacity: 1;
      }
    }
  }
  .footer-bottom{
    padding: 20px 0;
    background: rgba(255,255,255,0.4);
    .copyright{
      p{

        margin: 0;
        a {
          color: @body-color;
          &:hover {
            color: @primary-color;
          }
        }

      }
    }
    .copy-right-menu {
      li {
        position: relative;
        display: inline-block;
        position: relative;
        padding: 0px 19px 0 25px;
        a {
          color: @white-color;
          &:hover {
            color: @primary-color;
          }
        }
        &:before {
          display: block;
          content: "";
          position: absolute;
          font-size: 18px;
          background: @primary-color;
          left: 0;
          top: 50%;
          width: 6px;
          height: 6px;
          transform: translateY(-50%);
          border-radius: 50%;
        }
        &:first-child {
          &:before {
            display: none;
          }
        }
      }
    }

  }
  &.style1{
    .footer-top{
      .widget-title{
        color: @title-color3;
      }
      .site-map{
        li{
          a{
            &:hover{
              color: @blue-color;
            }
          }
        }
      }
      .footer-social {
        li {
          a {
            &:hover {
              background: @blue-color;
            }
          }
        }
      }
      .address-widget{
        li{
          .desc{
            a{
              &:hover {
                color: @blue-color;
              }

            }
          }
          i{
            color: @blue-color;
          }
        }
      }
      p{
        input[type="submit"]{
          background-image: linear-gradient(180deg , #00FFFF 19%, #f27c1e 100%);
        }
      }

    }
    .footer-bottom{
      .copyright{
        p{
          a {
            &:hover {
              color: @blue-color;
            }
          }

        }
      }
      .copy-right-menu {
        li {
          a {
            &:hover {
              color: @blue-color;
            }
          }
          &:before {
            background: @blue-color;
          }
        }
      }

    }
    &.home14-footer-style{
      .footer-bottom .copyright p a:hover,
      .footer-top .address-widget li .desc a:hover,
      .footer-top .address-widget li i,
      .footer-top .site-map li a:hover {
        color: @blue-color2;
      }
      .footer-top p input[type="submit"],
      .footer-bottom .copy-right-menu li:before,
      .footer-top .footer-social li a:hover {
        background: @blue-color2;
      }
    }
  }
  &.style2{
    //background: url(assets/images/bg/footer-bg2.jpg);
    background-color: #F5F6F9;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    .footer-top{
      padding: 92px 0 65px;
      .footer-logo{
        img{
          height: 40px;
        }
      }
      .widget-title{
        color: @white-color;
      }
      .site-map{
        li{

          a{
            color: @white-color;
            &:hover{
              color: #03228f;
            }
          }
        }
      }
      .footer-social {
        li {
          display: inline;
          margin-right: 5px;
          a {
            display: inline-block;
            width: 35px;
            height: 35px;
            line-height: 35px;
            border-radius: 50%;
            color: @white-color;
            text-align: center;
            font-size: 15px;
            transition: all .8s ease;
            background: rgba(255, 255, 255, 0.9);
            &:hover {
              color: @white-color;
              background: @primary-color;
            }
          }
          &:last-child {
            margin: 0;
          }
        }
      }
      .address-widget{
        li{
          padding: 0px 0px 16px 40px;
          position: relative;
          .desc{
            color: @white-color;
            a{
              color: @white-color;
              &:hover{
                color: #03228f;
              }
            }
          }
        }
      }
      .widget-desc{
        margin: 0;
      }
      p{

        input[type=email]{
          box-shadow: unset;
        }
      }
    }
    .footer-bottom{
      background: transparent;
      border-color: rgba(80,90,100,0.56) !important;
      border-top: 1px solid;
      .copyright{
        p{
          color: @white-color;
          a {
            color: @white-color;
            &:hover {
              color: @secondary-color;
            }
          }

        }
      }
      .copy-right-menu {
        li {
          a {
            color: @white-color;
          }
        }
      }
    }
    &.modify1{
      background: unset;
      background-color: unset;
      .footer-top{
        .site-map{
          li{
            a{
              &:hover{
                color: #1273EB;
              }
            }
          }
        }
        .address-widget{
          li{
            i{
              &:before{
                color: #fd6509;
              }
            }
            .desc{
              a{
                &:hover{
                  color: #1273EB;
                }
              }
            }
          }
        }

      }
      .footer-bottom{
        background: #000000;
        border-color: unset;
        border-top: unset;
        .copyright{
          p{
            color: @white-color;
            a {
              color: @white-color;
              &:hover {
                color: #1273EB;
              }
            }

          }
        }
        .copy-right-menu {
          li {
            a {
              color: @white-color;
              &:hover {
                color: #1273EB;
              }
            }
          }
        }
      }
    }
  }
  &.style3{
    background: #F5F6F9;
    background-size: cover;
    .footer-top{
      .widget-title{
        color: @title-color3;
      }
      .site-map{
        li{
          a{
            &:hover{
              color: #1273EB;
            }
          }
        }
      }
      .footer-social {
        li {
          a {
            &:hover {
              background: #787cf2;
            }
          }
        }
      }
      .address-widget{
        li{
          .desc{
            a{
              &:hover {
                color: #1273EB;
              }

            }
          }
          i{
            color: #1273EB;
          }
        }
      }
      p{
        input[type="submit"]{
          background: #787cf2;
        }
        input[type="submit"]:hover {
          opacity: .9;
        }
      }

    }
    .footer-bottom{
      .copyright{
        p{
          a {
            &:hover {
              color: #1273EB;
            }
          }

        }
      }
      .copy-right-menu {
        li {
          a {
            &:hover {
              color: #1273EB;
            }
          }
          &:before {
            background: #787cf2;
          }
        }
      }
    }
    &.onepage-style4{
      //background: url(assets/images/bg/footer-bg.png);
      background-color: #F5F6F9;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
</style>
