<template>
  <!--Header Start-->
  <header id="rs-header" class="rs-header style3 modify1 header-transparent">
    <!-- Menu Start -->
    <div class="menu-area menu-sticky">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-2">
            <div class="logo-part">
              <a href="/"><img src="/assets/images/logo-company/falcontech-logo.png" alt=""></a>
            </div>
            <div class="mobile-menu">
              <a href="#" class="rs-menu-toggle rs-menu-toggle-close secondary">
                <i class="fa fa-bars"></i>
              </a>
            </div>
          </div>
          <div class="col-lg-10 text-right">
            <div class="rs-menu-area">
              <div class="main-menu">
                <nav class="rs-menu pr-100 md-pr-0">
                  <ul id="onepage-menu" class="nav-menu">
                    <li  v-for="section in SECTIONS" :key="section.id"  class="current-menu-item">
                      <a :href="`#` + section.id">{{section.name}}</a>
                    </li>
                  </ul> <!-- //.nav-menu -->
                </nav>
              </div> <!-- //.main-menu -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Menu End -->
  </header>
  <!--Header End-->
</template>

<script setup>
const SECTIONS = [
  {name: "Home", id: "rs-home"},
  {name: "About", id: "rs-about"},
  {name: "Services", id: "rs-services"},
  {name: "Clients", id: "rs-clients"},
  {name: "Members", id: "rs-members"},
  {name: "News", id: "rs-news"},
  {name: "Contact", id: "rs-contact"},
]
</script>

<style lang="less" scoped>
/* -----------------------
    03. Header Section CSS
--------------------------*/
.full-width-header{
  position: relative;
  .rs-header{
    position: relative;
    .topbar-area {
      padding-top: 20px;
      padding-bottom: 10px;
      .rs-contact-info{
        .contact-part{
          display: inline-block;
          padding-right: 25px;
          padding-left: 25px;
          border-right: 1px solid #eee;
          font-size: 14px;
          i{
            float: left;
            margin-right: 15px;
            line-height: 38px;
            &:before{
              font-size: 26px;
              color: @primary-color;
            }
          }
          .contact-info{
            float: left;
            text-align: left;
            display: block;
            span{
              color: @title-color2;
              display: block;
              text-align: left;
              font-size: 15px;
              padding: 0;
              font-weight: 600;
              line-height: 1;
              margin-bottom: 0;
            }
            a{
              color: @body-color;
              font-size: 14px;
              &:hover{
                color: @primary-color;
              }
            }
          }
        }
      }
    }
    .menu-area {
      transition: @transition;
      background-image: linear-gradient(90deg, #03228f 10%, #0e73e4 100%);
      .logo-area{
        a{
          img{
            transition: 0.4s;
            max-height: 40px;
          }
          .sticky-logo{
            display: none;
          }
        }
      }
      .rs-menu-area {
        align-items: center;
        justify-content: flex-end;
        float: left;
      }
      .main-menu{
        .rs-menu{
          ul{
            &.nav-menu{
              li{
                display: inline-block;
                margin-right: 24px;
                padding: 0;
                a {
                  transition: @transition;
                  font-size: 15px;
                  //font-weight: 600;
                  margin-right: 10px;
                  height: 65px;
                  line-height: 65px;
                  padding: 0;
                  color: @color-title !important;
                  position: relative;
                  &:hover{
                    color: #bbb;
                  }
                }
                &.menu-item-has-children {
                  > a {
                    padding-right: 17px;
                    &:before {
                      content: "+";
                      position: absolute;
                      left: auto;
                      right: 0;
                      top: 50%;
                      text-align: center;
                      z-index: 9999;
                      font-size: 16px;
                      display: block;
                      cursor: pointer;
                      transform: translateY(-50%);
                      font-weight: 400;
                    }
                  }
                  &:hover {
                    > a {
                      &:before {
                        content: "-";
                      }
                    }
                  }
                }
                &.current-menu-item {
                  > a {
                    color: #bbb;
                  }
                }
                &:last-child{
                  margin-right: 0!important;
                  a {
                    margin-right: 0!important;
                  }
                  i{
                    margin: 0;
                  }
                }
              }
            }
            &.sub-menu{
              background: @white-color;
              margin: 0;
              padding: 20px 0;
              border-radius: 0 0 5px 5px;
              li{
                margin: 0 !important;
                border: none;
                a{
                  line-height: 25px;
                  padding-top: 10px;
                  padding-bottom: 10px;
                  padding-left: 30px;
                  padding-right: 30px;
                  margin: 0;
                  font-size: 14px;
                  font-weight: 500;
                  color: @title-color;
                  height: unset;
                  line-height: 16px;
                  &:before {
                    right: 30px !important;
                  }
                  &:hover{
                    color: @primary-color;
                  }
                }
                &.menu-item-has-children {
                  a {
                    &:before {
                      // display: none;
                    }
                  }
                }
                &.current-menu-item,
                &.active {
                  > a {
                    color: @primary-color !important;
                  }
                }
                &:last-child{
                  margin: 0;
                }
              }
              &.left{
                left: auto;
                right: 100%;
              }
            }
            &.mega-menu{
              position: absolute;
              left: 0;
              min-width: 1210px;
              box-shadow: 0 0 5px rgba(0,0,0,.15);
              .mega-menu-container{
                float:left;
                width:100%;
                position:relative;
                padding: 0;
                margin: 0 !important;
                .single-megamenu {
                  width: 25%;
                  float: left;
                  .sub-menu {
                    position:relative;
                    padding: 30px 35px 35px;
                    border-radius: 0;
                    box-shadow: none;
                    .menu-title {
                      color: @title-color;
                      padding: 10px 30px;
                      font-size: 17px;
                      font-weight: 600;
                      padding: 10px 30px;
                      text-align: left;
                      text-transform: capitalize;
                      transition: @transition;
                    }
                    li {
                      width:100%;
                      a {
                        width:100%;
                        padding: 10px 30px;
                      }
                    }
                  }
                  &:hover {
                    .sub-menu {
                      .menu-title {
                        color: @primary-color;
                      }
                    }
                  }
                }
              }
              &.two-column {
                min-width: 650px;
                .mega-menu-container{
                  .single-megamenu {
                    width: 50%;
                  }
                }
              }
              &.three-column {
                .mega-menu-container{
                  .single-megamenu {
                    width: 33.33%;
                  }
                }
              }
            }
          }
          ul li a:hover,
          ul li.active a,
          ul li.current-menu-item > a{
            color: #fff;
          }
        }
      }
      &.sticky {
        background: #fff;
        .logo-area{
          display: inline-block;
          top: 18px;
          a{
            .sticky-logo{
              display: block;
              max-height: 30px;
            }
          }
        }
        .rs-menu-area {
          float: right;
        }
        .main-menu {
          .rs-menu {
            ul {
              &.nav-menu {
                > li {
                  > a {
                    color: #0a0a0a;
                    &:hover {
                      color: @primary-color;
                    }
                  }
                  &.current-menu-item {
                    > a {
                      color: @primary-color;
                    }
                  }
                }
              }
              &.mega-menu {
                left: unset;
                right: 0;
              }
            }
          }
        }
        .expand-btn-inner {
          line-height: unset;
          height: unset;
          &.search-icon{
            .sidebarmenu-search > a{
              color: @title-color;
            }
          }
          li {
            .cart-icon {
              .cart-count {
                background: @primary-color;
                color: @white-color;
              }
            }
            > a {
              color: #494949;
              &:hover {
                color: @primary-color;
              }
            }
          }
          &.hidden-sticky {
            display: none;
          }
        }
      }
    }
    .expand-btn-inner{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      line-height: 65px;
      height: 65px;
      position: relative;
      .sidebarmenu-search{
        > a {
          color: @white-color;
          border-right: 1px solid #fff;
          padding-right: 20px;
          i {
            &:before {
              font-size: 15px;
              font-weight: 600;
            }
            &.flaticon-shopping-bag-1 {
              &:before {
                font-weight: normal;
              }
            }
          }
          &:hover {
            color: @white-color;
          }
        }
      }
      .toolbar-sl-share{
        padding-left: 25px;
        .social{
          li{
            display: inline-block;
            margin-right: 14px;
            a{
              color: @white-color;
              font-size: 14px;
              &:hover{
                opacity: 0.8;
              }
            }
            &:last-child{
              margin-right: 0;
            }
          }
        }
      }
    }
    &.style3{
      .menu-area {
        background: transparent;
        padding: 13px 0;
        .logo-part {
          a{
            .sticky-logo{
              display: none;
            }
          }
          img{
            transition: 0.4s;
            -webkit-transition: 0.4s;
            max-height: 40px;

          }
          .dark {
            display: none;
          }
          .light {
            display: inherit;
          }
        }
        .rs-menu-area {
          display: flex;
          width: 100%;
          position: relative;
          align-items: center;
          justify-content: flex-end;
        }
        .main-menu{
          .rs-menu{
            ul{
              &.nav-menu{
                li{
                  display: inline-block;
                  padding: 0;
                  &.menu-item-has-children {
                    > a {

                    }
                    &:hover {
                      > a {
                        &:before {
                          content: "-";
                        }
                      }
                    }
                  }
                }
              }
              &.sub-menu{
                li{
                  a{
                    line-height: 16px;
                    height: unset;
                  }
                  &.menu-item-has-children {
                    a {
                      &:before {
                        display: none;
                      }
                    }
                  }
                }
              }
              &.mega-menu{
                left: unset;
                right: -15px;
              }
            }
          }
        }
        &.sticky {
          background: @navbar !important;
          .logo-part{
            a{
              .sticky-logo{
                display: block;
              }
              .normal-logo{
                display: none;
              }
            }
          }
          .main-menu {
            .rs-menu {
              ul {
                &.nav-menu {
                  li{
                    a {
                      color: @title-color;
                      &:hover{
                        color: @primary-color;
                      }
                    }
                    &.current-menu-item {
                      > a {
                        color: @primary-color;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .expand-btn-inner{
        ul{
          display: flex;
          align-items: center;
          .sidebarmenu-search{
            > a {
              border: unset;
              padding-right: unset;
            }
          }
          li{
            .humburger {
              max-width: 45px;
              display: flex;
              flex-wrap: wrap;
              margin: -5px;
              span {
                height: 5px;
                width: 5px;
                display: block;
                background: @white-color;
                border-radius: 50%;
                transition: none;
                list-style: none;
                transition: @transition;
                margin: 3px 4px;
                &.dot2,
                &.dot4,
                &.dot6,
                &.dot8 {
                  background: #6394bf;
                }
              }
            }
          }
        }
      }
      &.modify1{
        .menu-area {
          .main-menu{
            .rs-menu{
              ul{
                &.nav-menu{
                  li{
                    display: inline-block;
                    padding: 0;
                    a {
                      color: @title-color;
                      &:hover{
                        color: @primary-color;
                      }
                    }
                    &.menu-item-has-children {
                      > a {

                      }
                      &:hover {
                        > a {
                          &:before {
                            content: "-";
                          }
                        }
                      }
                    }
                    &.current-menu-item {
                      > a {
                        color: @primary-color;
                      }
                    }
                  }
                }
                &.sub-menu{
                  li{

                    a{
                      color: @title-color;
                      &:hover{
                        color: @primary-color;
                      }
                    }
                    &.menu-item-has-children {
                      a {
                        &:before {
                          display: none;
                        }
                      }
                    }
                    &.active{
                      a {
                        color: @primary-color;
                      }
                    }
                  }
                }
                &.mega-menu{
                  right: -75px;
                  margin-top: 13px;
                }
              }
            }
          }
        }
        .expand-btn-inner{
          ul{
            .sidebarmenu-search{
              a{
                i{
                  color: @title-color;
                }
              }
            }
            li{
              margin-right: 30px;
              .humburger {
                span {
                  background: @title-color;
                  &.dot2,
                  &.dot4,
                  &.dot6,
                  &.dot8 {
                    background: #6394bf;
                  }
                }
              }
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
      &.modify2{
        .menu-area {
          .main-menu{
            .rs-menu{
              ul{
                &.mega-menu{
                  right: -75px;
                  margin-top: 13px;
                }
              }
            }
          }
          &.sticky{
            .expand-btn-inner{
              ul{
                li{
                  .humburger {
                    span {
                      background: #0e44dd;
                      &.dot2,
                      &.dot4,
                      &.dot6,
                      &.dot8 {
                        background: #333333;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .expand-btn-inner{
          ul{
            li{
              margin-right: 30px;
              .humburger {
                span {
                  background: @white-color;
                  &.dot2,
                  &.dot4,
                  &.dot6,
                  &.dot8 {
                    background: #6394bf;
                  }
                }
              }
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
      &.modify3{
        .menu-area {
          padding: 0;
          .row-table{
            display: flex;
            width: 100%;
            position: relative;
            align-items: center;
            justify-content: space-between;
            .main-menu{
              .rs-menu{
                ul{
                  &.nav-menu{
                    li{
                      display: inline-block;
                      padding: 0;
                      margin-right: 20px;
                      a {
                        color: @white-color;
                        &:hover{
                          color: #101010;
                        }
                      }
                      &.menu-item-has-children {
                        > a {

                        }
                        &:hover {
                          > a {
                            &:before {
                              content: "-";
                            }
                          }
                        }
                      }
                      &.current-menu-item {
                        > a {
                          color: #101010;
                        }
                      }
                    }
                  }
                  &.sub-menu{
                    li{
                      a{
                        line-height: 16px;
                        height: unset;
                        color: @title-color3;
                        &:hover{
                          color: #787cf2;
                        }
                      }
                      &.menu-item-has-children {
                        a {
                          &:before {
                            display: none;
                          }
                        }
                      }
                      &.current-menu-item,
                      &.active {
                        > a {
                          color: #787cf2 !important;
                        }
                      }
                    }
                  }
                  &.mega-menu{
                    right: -310px;
                  }
                }
                ul li a:hover,
                ul li.active a,
                ul li.current-menu-item > a{
                  color: #787cf2;
                }
              }
            }
          }
          &.sticky{
            .main-menu {
              .rs-menu {
                ul {
                  &.nav-menu {
                    > li {
                      > a {
                        color: white;
                        &:hover {
                          color: #787cf2;
                        }
                      }
                      &.current-menu-item {
                        > a {
                          color: #787cf2;
                        }
                      }
                    }
                  }
                }
              }
            }
            .expand-btn-inner{
              ul{
                .sidebarmenu-search{
                  > a {

                    &:hover {
                      color: #787cf2;
                    }
                  }
                }
                li{
                  .humburger {
                    span {
                      background: #787cf2;
                      margin: 2px 4px;
                      &.dot2,
                      &.dot4,
                      &.dot6,
                      &.dot8 {
                        background: #101010;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .expand-btn-inner{
          ul{
            .sidebarmenu-search{
              > a {
                color: @white-color;
                i {
                  &:before {
                    font-size: 18px;
                    font-weight: 600;
                  }
                }
                &:hover {
                  color: #101010;
                }
              }
            }
            li{
              margin-right: 30px;
              .humburger {
                span {
                  background: @white-color;
                  margin: 2px 4px;
                  &.dot2,
                  &.dot4,
                  &.dot6,
                  &.dot8 {
                    background: #101010;
                  }
                }
              }
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
        &.home14-style{
          .expand-btn-inner ul .sidebarmenu-search > a:hover,
          .menu-area .row-table .main-menu .rs-menu ul.nav-menu li a:hover {
            color: @blue-color2;
          }
          .menu-area .main-menu .rs-menu ul.mega-menu .mega-menu-container .single-megamenu:hover .sub-menu .menu-title {
            color: @blue-color2;
          }
          .menu-area .row-table .main-menu .rs-menu ul.sub-menu li.active > a{
            color: @blue-color2 !important;
          }
          .menu-area .row-table .main-menu .rs-menu ul.nav-menu li.current-menu-item > a {
            color: @blue-color2;
            line-height: 100px;
            height: 100px;
          }
          .menu-area{
            &.sticky{
              .expand-btn-inner{
                ul{
                  .sidebarmenu-search{
                    > a {

                      &:hover {
                        color: @blue-color2;
                      }
                    }
                  }
                  li{
                    .humburger {
                      span {
                        background: @blue-color2;
                        &.dot2,
                        &.dot4,
                        &.dot6,
                        &.dot8 {
                          background: #787cf2;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .expand-btn-inner{
            ul{
              li{
                margin-right: 30px;
                .humburger {
                  span {
                    background: @white-color;
                    margin: 2px 4px;
                    &.dot2,
                    &.dot4,
                    &.dot6,
                    &.dot8 {
                      background: @blue-color2;
                    }
                  }
                }
                &:last-child {
                  margin-right: 0;
                }
              }
            }
          }

        }
      }
    }
    &.header-transparent{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 999;
    }
  }
  .mobile-menu{
    .mobile-logo {
      float: left;
      img {
        max-height: 30px;
      }
    }
    .rs-menu-toggle{
      color: @color-title;
      float: right;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .right_menu_togle{
    background-image: linear-gradient( 90deg, #e2e9f7 0%, #ffffff 100%);
    padding: 50px;
    width: 500px;
    right: -500px;
    z-index: 999999;
    transition: @transition;
    position: fixed;
    .close-btn{
      overflow: visible;
      .nav-link{
        position: absolute;
        right: 25px;
        top: 25px;
        .humburger {
          max-width: 45px;
          display: flex !important;
          flex-wrap: wrap;
          margin: -5px;
          span {
            height: 5px;
            width: 5px;
            display: block;
            background: @title-color2;
            border-radius: 50%;
            transition: none;
            list-style: none;
            transition: @transition;
            margin: 4px 5px;
            &.dot2,
            &.dot4,
            &.dot6,
            &.dot8 {
              background: @primary-color;
            }
          }
        }
      }
    }
    .canvas-logo{
      padding-left: 0;
      padding-bottom: 25px;
      img {
        max-height: 36px;
      }
    }
    .sidebarnav_menu{
      li{
        a{
          font-size: 17px;
          color: #222;
          padding-left: 0;
          &:hover{
            color: @primary-color;
          }
        }
      }
    }
    .canvas-contact{
      padding: 25px 0 0;
      .address-area{
        .address-list{
          display: flex;
          margin-top: 18px;
          .info-icon{
            i{
              &:before{
                margin: 0 20px 0 0;
                font-size: 25px;
                color: #0b70e1;
              }
            }
          }
          .info-content{
            .title{
              font-size: 16px;
              line-height: 26px;
              font-weight: 600;
              color: @title-color;
              margin-bottom: 5px;
            }
            em{
              display: block;
              font-style: normal;
              line-height: 22px;
              font-size: 15px;
              a{
                color: #333333;
              }
            }
          }
        }
      }
      .social{
        margin: 50px 0 0;
        li{
          display: inline-block;
          padding-right: 10px;
          a{
            i{
              font-size: 15px;
              color: #fff;
              text-align: center;
              background: #032390;
              width: 35px;
              height: 35px;
              line-height: 35px;
              border-radius: 3px;
            }
            &:hover{
              opacity: 0.82;
            }
          }
          &:last-child{
            padding: 0;
          }
        }
      }
    }
    &.home14-style-togle{
      .canvas-contact .address-area .address-list .info-content em a:hover {
        color: @blue-color2;
      }
      .canvas-contact .social li a i {
        background: @blue-color2;
      }
    }
  }
}
body {
  .search-modal {
    background: #0B70E1;
    position: fixed;
    top: 0;
    bottom: 0;
    background: rgba(16, 110, 234, 0.98);
    width: 100%;
    height: 100%;
    z-index: 9999;
    left: 0;
    .modal-content {
      position: initial;
      border: 0;
      background: transparent;
    }
    .search-block {
      input {
        height: 60px;
        line-height: 60px;
        padding: 0 15px;
        background: transparent;
        border-width: 0 0 1px 0;
        border-radius: 0;
        border-color: rgba(255,255,255,0.4);
        box-shadow: none;
        color: #ffffff;
        font-weight: 600;
        font-size: 18px;
      }
      ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #ffffff;
        opacity: 1;
      }
      ::-moz-placeholder { /* Firefox 19+ */
        color:#ffffff;
        opacity: 1;
      }
      :-ms-input-placeholder { /* IE 10+ */
        color: #ffffff;
        opacity: 1;
      }
      :-moz-placeholder { /* Firefox 18- */
        color: #ffffff;
        opacity: 1;
      }
    }
    .close {
      position: fixed;
      right: 50px;
      top: 50px;
      background: #ffffff;
      color: #0a0a0a;
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
      border: none;
      border-radius: 50%;
      transition: all .5s ease;
      font-size: 18px;
      span::before {
        font-size: 18px;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }

}
</style>
