<template>
  <!-- Technology Section Start -->
  <div id="rs-news" class="rs-news style6 modify1 pt-20 pb-100 md-pt-80 md-pb-80">
    <div class="container">
      <div class="sec-title text-center">
        <h2 class="title py-5"><span>News</span></h2>
      </div>
      <div class="row">
          <div v-for="news in NEWS" :key="news.title" class="project-item col-md-6">
            <a target="_blank" :href="news.link">
            <div class="project-img text-center mb-5">
              <img :src="`assets/images/news/` + news.image" alt="images">
            </div>
            <div class="project-content">
              <h5 class="title pb-60 px-3">
                <a>{{ news.title }}</a>
              </h5>
            </div>
            </a>
        </div>
      </div>
    </div>
  </div>
  <!-- Technology Section End -->
</template>

<script setup>
const NEWS = [
  {title: 'China drafts rules for using facial recognition technology', image: 'img_2.png', link: 'https://www.itnews.com.au/news/china-drafts-rules-for-using-facial-recognition-technology-598954'},
  {title: 'Vietnam ready to become self-reliant in technology: FPT leader', image: 'img_1.png', link: 'https://vietnamnet.vn/en/vietnam-ready-to-become-self-reliant-in-technology-fpt-leader-817103.html'},
  {title: 'Why an onshore security cloud gives your IT team the edge', image: 'img_3.png', link: 'https://www.itnews.com.au/feature/why-an-onshore-security-cloud-gives-your-it-team-the-edge-598131'},
  {title: 'Moomoo employs secure, highly-available IT architecture to enable 24/5 trading of selected US stocks and ETFs', image: 'img_4.png', link: 'https://www.itnews.com.au/feature/moomoo-employs-secure-highly-available-it-architecture-to-enable-24-5-trading-of-selected-us-stocks-and-etfs-597981'},
]
</script>

<style lang="less" scoped>
.rs-news{
  &.style6{
    .project-item{
      position: relative;
      overflow: hidden;
      transition: @transition;
      .project-img{
        width: 550px;
        height: 300px;
        position: relative;
        img{
          border-radius: 5px;
        }
      }
      .project-content{
        margin-top: 25px;
        .title{
          font-size: 18px;
          font-weight: unset !important;
          //line-height: 1.1;
          margin-bottom: 5px;
          margin-right: 30px;
          a{
            color: @title-color;
            text-decoration: none !important;
            &:hover{
              color: @primary-color;
            }
          }
        }
        .category{
          font-size: 16px;
          line-height: 27px;
          font-weight: 400;
          display: block;
          a{
            color: @primary-color;
          }
        }
      }
      &:hover{
        .project-img{
          .plus-icon{
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
    &.modify1{
      .project-item{
        .project-img{
          .plus-icon{
            background: #0080FF;
            i{
              font-size: 16px;
              font-weight: 300;
              color: @white-color;
            }
          }
        }
        .project-content{
          margin-top: 25px;
          .title{
            font-size: 23px;
            font-weight: 700;
            line-height: 46px;
            margin-bottom: 5px;
            text-decoration: underline;
            a{
              color: @title-color;
              &:hover{
                color: @primary-color;
              }
            }
          }
        }
      }
    }
  }
}
</style>
