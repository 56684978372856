<template>
  <!-- About Section Start -->
  <div id="rs-about" class="rs-about style4 pt-100 pb-100 md-pb-70">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="testing-img">
            <img src="/assets/images/about/introduce.jpg" alt="">
          </div>
        </div>
        <div class="col-lg-6 pl-60 md-pl-15 md-pt-60">
          <div class="about-content">
            <h2 class="title">Why Falcon Tech Software Solution</h2>
            <ul v-for="text in TEXTS" :key="text.text" class="rs-features-list">
              <i class="fa fa-check"></i>
              <li>
                <span>{{text.text}}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- About Section End -->
</template>

<script setup>
const TEXTS = [
  {text: 'We specialize in providing customized software solutions to individuals, organizations, and businesses.'},
  {text: 'Our services range from software design and development to maintenance and support.' },
  {text: 'Our approach to software development is collaborative, and our team works closely with our clients to ensure. '},
  {text: 'We pride ourselves on staying up-to-date with the latest technologies and industry trends.'},
]
</script>

<style lang="less" scoped>
.rs-about{
  .rs-animation-shape{
    position: relative;
    z-index: 1;
    .pattern{
      position: absolute;
      top: 22px;
    }
    .middle{
      position: absolute;
      left: -165px;
      top: -56px;
      z-index: -1;
    }
    .bottom-shape{
      position: absolute;
      left: -165px;
      top: 395px;
      z-index: -1;
    }
    .middle-image2{
      position: absolute;
      top: -80px;
      left: -25px;
      z-index: -1;
    }
  }
  .shape-image{
    position: relative;
    .top{
      position: absolute;
      right: 51%;
      top: -125px;
    }
    .bottom{
      position: absolute;
      right: 0;
      top: 145px;
    }
  }
  &.style4{
    .about-content{
      .logo-img{
        margin-bottom: 15px;
        img{
          width: 71px;
        }
      }
      .title{
        font-size: 36px;
        line-height: 46px;
        font-weight: 700;
        color: #102B3E;
        margin-bottom: 30px;
        padding-right: 24px;
        span{
          color: #0080FF;
        }
      }
      p{
        padding-right: 121px;
        margin-bottom: 40px;
      }
    }
  }
  &.home14-about-style{
    .about-bottom-section{
      position: relative;
      z-index: 9;
      .addon-services{
        margin: -88px 26px 0px -20px;
        padding: 15px 20px 15px 20px;
        background-image: linear-gradient( 120deg, #1642FD 0%, #B12BF3 100%);
        border-radius: 10px 10px 10px 10px;
        display: flex;
        align-items: center;
        .icon-images{
          img{
            max-width: unset;
            width: 50px;
            height: 50px;
          }
        }
        .services-text{
          margin: 0px 0px 0px 20px;
          .title{
            font-size: 16px;
            font-weight: 500;
            line-height: 1.4em;
            color: @white-color;
            margin: 0 0 10px;
          }
        }
      }
    }
  }
  .software-img{
    img{
      max-width: 600px;
    }
  }
}
</style>
