<template>
  <div id="rs-clients" class="location py-5">
    <div class="container">
      <div  class="row">
        <div><h2 class="home_text title-client text-center pb-4">Our Clients</h2></div>
        <div v-for="client in CLIENTS" :key="client.project"  class="col-lg-3 align-items-center">
          <div class="ms-2">
            <a class="clients" :href="client.link" target="_blank">
              <h4 class=" h3-sub-text text-center">{{client.project}}</h4>
              <p class=" fw-lighter text-center">{{client.country}}</p>
              <div class="text-center">
                <img alt="{{client.project}}" style="width: 80%"
                     :src="`assets/images/clients/` + client.imgUrl">
              </div>
            </a>
          </div>
        </div>
      </div> <!-- end of row -->
    </div> <!-- end of container -->
  </div> <!-- end of location -->
</template>

<script setup>
const CLIENTS = [
  {project: 'Payroll Engine', country: 'Luxembourg Company', imgUrl: 'payroll.png', link: 'https://www.payrollengine.lu/why-us'},
  {project: 'Operr Technology', country: 'USA Company', imgUrl: 'operr-removebg-preview.png', link: 'https://www.operr.com/'},
  {project: 'Adtech', country: 'Israel Company', imgUrl: 'mobitech-removebg-preview.png', link: 'https://mobitech.io/'},
  {project: 'FirstOfferz', country: 'Israel Company', imgUrl: 'firstOfferz-removebg-preview.png', link: 'https://firstofferz.com/'}

]
</script>

<style scoped>
h3,p {
  color: #00152B;
}

</style>
