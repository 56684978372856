<template>
  <!-- Services Section Start -->
  <div id="rs-services" class="rs-services style6 pt-60 pb-60 md-pt-80 md-pb-80">
    <div class="container">
      <div class="row">
        <div><h2 class="home_text title-client text-center pb-4">Services</h2></div>
        <div v-for="service in SERVICES" :key="service.serviceName" class="services col-lg-4">
          <div class="package-services">
            <div class="services-item">
              <div class="services-icon">
                <i :class="service.icon"></i>
              </div>
              <div class="services-content">
                <div class="services-title">
                  <h3 class="title"><a href="web-development.html">{{ service.serviceName }}</a></h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Services Section End -->
</template>

<script setup>
const SERVICES = [
  {
    serviceName: 'Software Outsourcing', icon: 'fa fa-hdd-o fa-3x p-2', img: 'outsourcing.png',
    line: 'Outsourcing is the process of hiring a company or other software developer to develop or maintain the software for your business.'
  },
  {
    serviceName: 'Global Development Hub', icon: 'fa fa-globe fa-3x p-2', img: 'GDH.png',
    line: 'The Global Development Center is a software platform designed to assist developers and project managers in managing and implementing global development projects.'
  },
  {
    serviceName: 'Products Research & Development', icon: 'fa fa-tasks fa-3x p-2', img: 'RnD.png',
    line: 'In the field of manufacturing, product research and development (Products Research & Development) is the land that businesses want to explore to achieve a higher level of competition in the market.'
  }
];
</script>

<style lang="less" scoped>
i {
  color: #fff;
  width: 65px;
  background-color: transparent;
  background-image: linear-gradient(180deg, #3d57ad 0%, #1f2741 100%);
  background-image: linear-gradient(180deg, #00FFFF 0%, #0080FF 79%);
  padding: 10px;
  margin: 0px 20px 0px 0px;
  border-radius: 50em;
}
.rs-services{
  &.main-home{
    .services-item{
      margin: 0;
      padding: 60px 25px 50px 35px;
      border-radius: 10px 10px 10px 10px;
      .services-icon{
        margin-bottom: 30px;
        .image-part{
          img{
            width: 80px;
            height: 80px;
          }
        }
      }
      .services-content{
        .services-text{
          .services-title{
            font-size: 22px;
            font-weight: 600;
            line-height: 30px;
            margin-bottom: 10px;
            a{
              color: @title-color;
              &:hover{
                color: @primary-color;
              }
            }
          }
        }
        .services-desc{
          p{
            color: #444;
            margin: 0;
          }
        }
      }
    }
  }
  .bg-section{
    background: @white-color;
    .shape-part{
      .left-side{
        position: absolute;
        bottom: 0px;

      }
      .right-side{
        position: absolute;
        bottom: 0px;
        right: 0;
      }
    }
  }
  &.style6{
    .services-item{
      padding: 0px 44px 0px;
      text-align: center;
      .services-icon{
        padding-bottom: 35px;
        img{
          transition: all ease .3s;
          width: 200px;
          height: 200px;
        }
      }
      .services-content{
        .services-title{
          .title{
            font-size: 24px;
            font-weight: 600;
            line-height: 34px;
            margin: 0px 20px 8px 20px;
            a{
              color: @title-color;
              &:hover{
                color: @primary-color;
              }
            }
          }
        }
      }
    }
  }
}
</style>
