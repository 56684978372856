<template>
  <div class="offwrap"></div>

  <!--Preloader area start here-->
  <div id="loader" class="loader">
    <div class="loader-container"></div>
  </div>
  <!--Preloader area End here-->

  <!-- Main content Start -->
  <div class="main-content">
    <div class="full-width-header">
      <!--Header Start-->
      <header-component/>
      <!--Header End-->
    </div>

    <section class="section">
      <home-component/>
    </section>

    <section class="section">
      <about-component/>
    </section>

    <section class="section">
      <services-component/>
    </section>

    <section class="section">
      <our-clients-component/>
    </section>

    <section class="section">
      <members-component/>
    </section>

    <section class="section">
      <news-component/>
    </section>

    <section class="section">
      <contact-component/>
    </section>

    <section class="section">
      <footer-component/>
    </section>
  </div>

  <!-- start scrollUp  -->
  <div id="scrollUp" class="orange-color">
    <i class="fa fa-angle-up"></i>
  </div>
  <!-- End scrollUp  -->

  <!-- Search Modal Start -->
  <div class="modal fade search-modal" id="searchModal" tabindex="-1" aria-labelledby="searchModalLabel" aria-hidden="true">
    <button type="button" class="close" data-bs-dismiss="modal">
      <span class="flaticon-cross"></span>
    </button>
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="search-block clearfix">
          <form>
            <div class="form-group">
              <input class="form-control" placeholder="Search Here..." type="text">
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- Search Modal End -->
</template>

<script setup>
/* eslint-disable no-undef */
import {onMounted} from "vue";
// import CanvasMenuComponent from "@/components/canvas-menu-component";
import HomeComponent from "@/components/home-component";
import ServicesComponent from "@/components/services-component";
import AboutComponent from "@/components/about-component";
import OurClientsComponent from "@/components/our-clients-component";
import ContactComponent from "@/components/contact-component";
import FooterComponent from "@/components/footer-component";
import HeaderComponent from "@/components/header-component";
import MembersComponent from "@/components/members-component";
import NewsComponent from "@/components/news-component";

onMounted(() => {
  let header = $('.menu-sticky');
  let win = $(window);

  win.on('scroll', function() {
    let scroll = win.scrollTop();
    if (scroll < 1) {
      header.removeClass("sticky");
    } else {
      header.addClass("sticky");
    }

    $("section").each(function() {
      let elementTop = $(this).offset().top - $('#rs-header').outerHeight();
      if(scroll >= elementTop) {
        $(this).addClass('loaded');
      }
    });

  });

  //window load
  $(window).on( 'load', function() {
    $("#loading").delay(1500).fadeOut(500);
    $("#loading-center").on( 'click', function() {
      $("#loading").fadeOut(500);
    })
  })

  // Parallax Stuff
  if ($("#stuff").length) {
    let stuff = $('#stuff').get(0);
    // eslint-disable-next-line no-unused-vars
    let parallaxInstance = new Parallax(stuff);
  }

  // onepage nav
  // let navclose = $('#onepage-menu');
  // if(navclose.length){
  //   $(".nav-menu li a").on("click", function () {
  //     if ($(".showhide").is(":visible")) {
  //       $(".showhide").trigger("click");
  //     }
  //   });
  //
  //   if ($.fn.onePageNav) {
  //     $(".nav-menu").onePageNav({
  //       currentClass: "current-menu-item"
  //     });
  //   }
  // }

  //Testimonials Slider
  let sliderfor = $('.slider-for');
  if(sliderfor.length){
    $('.slider-for').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      asNavFor: '.slider-nav',
      autoplay: true
    });
  }
  let slidernav = $('.slider-nav');
  if(slidernav.length){
    $('.slider-nav').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      asNavFor: '.slider-for',
      dots: false,
      centerMode: true,
      focusOnSelect: true,
    });
  }


  if ($('.team-style4').length) {
    $('.team-style4').slick({
      autoplay: true,
      autoplaySpeed: 2000,
      speed: 2000,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: true,
      dots: false,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    });
  }

  if ($('.slider-for2').length) {
    $('.slider-for2').slick({
      autoplay: true,
      infinite: true,
      centerMode: false,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
          }
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
    });
  }

  // collapse hidden
  let navMain = $(".navbar-collapse");
  navMain.on("click", "a:not([data-toggle])", null, function () {
    navMain.collapse('hide');
  });

  // video
  if ($('.player').length) {
    $(".player").YTPlayer();
  }

  // wow init
  new WOW().init();

  // image loaded portfolio init
  let gridfilter = $('.grid');
  if(gridfilter.length){
    $('.grid').imagesLoaded(function() {
      $('.gridFilter').on('click', 'button', function() {
        let filterValue = $(this).attr('data-filter');
        $grid.isotope({
          filter: filterValue
        });
      });
      let $grid = $('.grid').isotope({
        itemSelector: '.grid-item',
        percentPosition: true,
        masonry: {
          columnWidth: '.grid-item',
        }
      });
    });
  }

  // project Filter
  if ($('.gridFilter button').length) {
    let projectfiler = $('.gridFilter button');
    if(projectfiler.length){
      $('.gridFilter button').on('click', function(event) {
        $(this).siblings('.active').removeClass('active');
        $(this).addClass('active');
        event.preventDefault();
      });
    }
  }

  // magnificPopup init
  let imagepopup = $('.image-popup');
  if(imagepopup.length){
    $('.image-popup').magnificPopup({
      type: 'image',
      callbacks: {
        beforeOpen: function() {
          this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure animated zoomInDown');
        }
      },
      gallery: {
        enabled: true
      }
    });
  }

  // Get a quote popup
  let popupquote = $('.popup-quote');
  if(popupquote.length){
    $('.popup-quote').magnificPopup({
      type: 'inline',
      preloader: false,
      focus: '#qname',
      removalDelay: 500,
      callbacks: {
        beforeOpen: function() {
          this.st.mainClass = this.st.el.attr('data-effect');
          if(win.width() < 800) {
            this.st.focus = false;
          } else {
            this.st.focus = '#qname';
          }
        }
      }
    });
  }

  //preloader
  $(window).on('load', function() {
    $("#loader").delay(1000).fadeOut(500);
  })

  //Videos popup jQuery
  let popupvideos = $('.popup-videos');
  if(popupvideos.length){
    $('.popup-videos').magnificPopup({
      disableOn: 10,
      type: 'iframe',
      mainClass: 'mfp-fade',
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false
    });
  }

  //CountDown Timer
  let CountTimer = $('.CountDownTimer');
  if(CountTimer.length){
    $(".CountDownTimer").TimeCircles({
      fg_width: 0.030,
      bg_width: 0.8,
      circle_bg_color: "#eeeeee",
      circle_fg_color: "#eeeeee",
      time: {
        Days:{
          color: "#032390"
        },
        Hours:{
          color: "#032390"
        },
        Minutes:{
          color: "#032390"
        },
        Seconds:{
          color: "#032390"
        }
      }
    });
  }

  let pieChart = $('.progress-pie-part');
  if(pieChart.length) {
    $('.chart').easyPieChart({
      size: 172,
      barColor: "#032390",
      scaleLength: 0,
      lineWidth: 8,
      trackColor: "#eeeeee",
      lineCap: "circle",
      animate: 2000,
    });
  }

  /*-------------------------------------
      OwlCarousel
  -------------------------------------*/
  $('.rs-carousel').each(function() {
    let owlCarousel = $(this),
        loop = owlCarousel.data('loop'),
        items = owlCarousel.data('items'),
        margin = owlCarousel.data('margin'),
        // eslint-disable-next-line no-unused-vars
        stagePadding = owlCarousel.data('stage-padding'),
        autoplay = owlCarousel.data('autoplay'),
        autoplayTimeout = owlCarousel.data('autoplay-timeout'),
        smartSpeed = owlCarousel.data('smart-speed'),
        dots = owlCarousel.data('dots'),
        nav = owlCarousel.data('nav'),
        navSpeed = owlCarousel.data('nav-speed'),
        xsDevice = owlCarousel.data('mobile-device'),
        xsDeviceNav = owlCarousel.data('mobile-device-nav'),
        xsDeviceDots = owlCarousel.data('mobile-device-dots'),
        smDevice = owlCarousel.data('ipad-device'),
        smDeviceNav = owlCarousel.data('ipad-device-nav'),
        smDeviceDots = owlCarousel.data('ipad-device-dots'),
        smDevice2 = owlCarousel.data('ipad-device2'),
        smDeviceNav2 = owlCarousel.data('ipad-device-nav2'),
        smDeviceDots2 = owlCarousel.data('ipad-device-dots2'),
        mdDevice = owlCarousel.data('md-device'),
        centerMode = owlCarousel.data('center-mode'),
        HoverPause = owlCarousel.data('hoverpause'),
        mdDeviceNav = owlCarousel.data('md-device-nav'),
        mdDeviceDots = owlCarousel.data('md-device-dots');
    owlCarousel.owlCarousel({
      loop: (loop ? true : false),
      items: (items ? items : 4),
      lazyLoad: true,
      center: (centerMode ? true : false),
      autoplayHoverPause: (HoverPause ? true : false),
      margin: (margin ? margin : 0),
      //stagePadding: (stagePadding ? stagePadding : 0),
      autoplay: (autoplay ? true : false),
      autoplayTimeout: (autoplayTimeout ? autoplayTimeout : 1000),
      smartSpeed: (smartSpeed ? smartSpeed : 250),
      dots: (dots ? true : false),
      nav: (nav ? true : false),
      navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
      navSpeed: (navSpeed ? true : false),
      responsiveClass: true,
      responsive: {
        0: {
          items: (xsDevice ? xsDevice : 1),
          nav: (xsDeviceNav ? true : false),
          dots: (xsDeviceDots ? true : false),
          center: false,
        },
        768: {
          items: (smDevice2 ? smDevice2 : 2),
          nav: (smDeviceNav2 ? true : false),
          dots: (smDeviceDots2 ? true : false),
          center: false,
        },
        992: {
          items: (smDevice ? smDevice : 3),
          nav: (smDeviceNav ? true : false),
          dots: (smDeviceDots ? true : false),
          center: false,
        },
        1200: {
          items: (mdDevice ? mdDevice : 4),
          nav: (mdDeviceNav ? true : false),
          dots: (mdDeviceDots ? true : false),
        }
      }
    });
  });

  // Skill bar
  let skillbar = $('.skillbar');
  if(skillbar.length) {
    $('.skillbar').skillBars({
      from: 0,
      speed: 4000,
      interval: 100,
      decimals: 0,
    });
  }

  // Counter Up
  let counter = $('.rs-count');
  if(counter.length) {
    $('.rs-count').counterUp({
      delay: 20,
      time: 1500
    });
  }

  // scrollTop init
  let totop = $('#scrollUp');
  win.on('scroll', function() {
    if (win.scrollTop() > 150) {
      totop.fadeIn();
    } else {
      totop.fadeOut();
    }
  });
  totop.on('click', function() {
    $("html,body").animate({
      scrollTop: 0
    }, 500)
  });

  //canvas menu
  let navexpander = $('#nav-expander');
  if(navexpander.length){
    $('#nav-expander, #nav-close, .offwrap').on('click',function(e){
      e.preventDefault();
      $('body').toggleClass('nav-expanded');
    });
  }

  // View Course
  $('.course-view-part .view-icons .view-grid').on('click',function(e){
    e.preventDefault();
    $('.rs-popular-courses').removeClass('list-view');
  });
  $('.course-view-part .view-icons .view-list').on('click',function(e){
    e.preventDefault();
    $('.rs-popular-courses').addClass('list-view');
  });


  /*----------------------------
    single-productjs active
    ------------------------------ */
  let singleproductimage = $('.single-product-image');
  if(singleproductimage.length){
    $('.single-product-image').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      asNavFor: '.single-product-nav'
    });
  }

  let singleproductnav = $('.single-product-nav');
  if(singleproductnav.length){
    $('.single-product-nav').slick({
      slidesToShow: 3,
      asNavFor: '.single-product-image',
      dots: false,
      focusOnSelect: true,
      centerMode:false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 591,
          settings: {
            slidesToShow: 2
          }
        }
      ]
    });
  }

  // categories btn
  $('.cat-menu-inner').hide();
  $('.cat-btn').on('click',function(){
    $('.cat-menu-inner').slideToggle();
  })

  let tilt = $('.js-tilt');
  if(tilt.length) {
    // eslint-disable-next-line no-unused-vars
    const tilt = $('.js-tilt').tilt();
  }
});
</script>

<style scoped>

.section:nth-child(odd) {
  background-color: white;
}

.section:nth-child(even) {
  background-color: #ECFCFF;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->

