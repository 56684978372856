<template>
  <!-- Contact Section Start -->
  <div id="rs-contact" class="rs-contact pt-120 gray-color">
    <div class="container">
      <div class="row">
        <div class="col-lg-4">
          <div class="contact-box">
            <div class="sec-title mb-45">
              <span class="sub-text new-text white-color">Let's Talk</span>
              <h2 class="title white-color">Speak With Expert Engineers.</h2>
            </div>
            <div class="address-box mb-25">
              <div class="address-icon">
                <i class="fa fa-home"></i>
              </div>
              <div class="address-text">
                <span class="label">Email:</span>
                <a>Falcontech.vn@gmail.com</a>
              </div>
            </div>
            <div class="address-box mb-25">
              <div class="address-icon">
                <i class="fa fa-phone"></i>
              </div>
              <div class="address-text">
                <span class="label">Phone:</span>
                <a href="#">(+84) 971 990 314</a>
              </div>
            </div>
            <div class="address-box">
              <div class="address-icon">
                <i class="fa fa-map-marker"></i>
              </div>
              <div class="address-text">
                <span class="label">Address:</span>
                <div class="desc">CIC Tower, 2/219 Trung Kinh, Yen Hoa, Cau Giay, Hanoi, Vietnam</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-8 pl-70 md-pl-15 md-mt-40">
          <div class="contact-widget onepage-style">
            <div class="sec-title2 mb-40">
              <h2 class="title testi-title">Contact with us</h2>

            </div>
            <div id="form-messages"></div>
            <form id="contact-form" method="post" action="mailer.php">
              <fieldset>
                <div class="row">
                  <div class="col-lg-12 mb-30 col-md-6 col-sm-6">
                    <input class="from-control" type="text" id="name" name="name" placeholder="Name" required="">
                  </div>
                  <div class="col-lg-12 mb-30 col-md-6 col-sm-6">
                    <input class="from-control" type="text" id="phone" name="phone" placeholder="Phone Number" required="">
                  </div>
                  <div class="col-lg-12 mb-30 col-md-6 col-sm-6">
                    <input class="from-control" type="text" id="email" name="email" placeholder="Email" required="">
                  </div>
                  <div class="col-lg-12 mb-30">
                    <textarea class="from-control" id="message" name="message" placeholder="Your message Here" required=""></textarea>
                  </div>
                </div>
                <div class="btn-part">
                  <div class="form-group mb-0">
                    <input class="readon learn-more submit" type="submit" value="Submit Now">
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="map-canvas pt-120 md-pt-70">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.3285372392806!2d105.78860137541781!3d21.019536488095056!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135abc9309b849b%3A0xc0df713d688de7cb!2sCIC%20Tower!5e0!3m2!1svi!2s!4v1691571227811!5m2!1svi!2s" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
  </div>
  <!-- Contact Section Start -->
</template>

<script>
export default {
  name: "contact-component"
}
</script>

<style lang="less" scoped>
.rs-contact{
  .contact-wrap{
    background: @white-color;
    padding: 25px 40px 50px;
    border-radius: 10px 10px 10px 10px;
    .content-part{
      text-align: center;
      .title{
        font-size: 24px;
        line-height: 40px;
        font-weight: 700;
        color: @title-color2;
        margin: 0;
      }
      .desc{
        margin: 0;
      }
    }
    .from-control{
      padding: 12px 16px 12px 16px;
      border-radius: 5px 5px 5px 5px;
      border-style: solid;
      border-color: #02010100;
      background-color: #F6F7F9;
      color: @body-color;
      width: 100%;
      opacity: 4;
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #454545;
      opacity: 1;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color:#454545;
      opacity: 1;
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: #454545;
      opacity: 1;
    }
    :-moz-placeholder { /* Firefox 18- */
      color: #454545;
      opacity: 1;
    }
  }
  .contact-box{
    background-image: linear-gradient(220deg, #00FFFF 0%, #0080FF 79%);
    padding: 80px 30px 80px 30px;
    border-radius: 5px 5px 5px 5px;
    .address-box{
      display: flex;
      .address-icon{
        background-image: linear-gradient(275deg, #FFFFFF 0%, #FCFCFF 100%);
        min-width: 48px;
        height: 48px;
        line-height: 48px;
        text-align: center;
        width: 40px;
        border-radius: 50%;
        margin-right: 23px;
        i{
          font-size: 20px;
          line-height: 20px;
          color: @blue-color;
        }
      }
      .address-text{
        .label{
          font-size: 16px;
          font-weight: 600;
          color: @white-color;
          display: block;
          margin-bottom: 5px;
        }
        a{
          color: @white-color;
          &:hover{
            color: #ccc;
          }
        }
        .desc{
          color: @white-color;
        }
      }
    }
    &.onepage-box{
      background-image: linear-gradient( 220deg , #f27c1e 0%, #00FFFF 79%);
      .address-box{
        .address-icon{
          background: @white-color;
          i{
            color: @blue-color;
          }
        }
      }
      &.modify1{
        background-image: linear-gradient(  250deg , #9C9FF9 0%, #6E71DB 100%);
        .address-box{
          .address-icon{
            background: @white-color;
            i{
              color: #787cf2;
            }
          }
        }
      }
      &.home14-onepage-style{
        background-image: linear-gradient( 250deg, #932FF5 0%, #343DFB 100%);
      }
    }
  }
  .contact-widget{
    .from-control{
      padding: 20px 20px 20px 20px;
      border-radius: 5px 5px 5px 5px;
      border-style: solid;
      border-width: 0;
      background-color: #F6F7F9;
      width: 100%;
      max-width: 100%;
    }
    textarea {
      height: 120px;
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #454545;
      opacity: 1;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: #454545;
      opacity: 1;
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: #454545;
      opacity: 1;
    }
    :-moz-placeholder { /* Firefox 18- */
      color: #454545;
      opacity: 1;
    }
    &.onepage-style{
      .from-control{
        background: #fff;
      }
    }
  }
  .map-canvas{
    overflow: hidden;
    iframe {
      float: left;
      width: 100%;
      height: 620px;
      border: unset;
    }
  }
  &.style2{
    .contact-box-wrap{
      background-image: linear-gradient(270deg, #4E95ED 0%, #03228F 100%);
      border-radius: 5px 5px 5px 5px;
      padding: 90px 60px 60px 60px;
      .from-control{
        padding: 16px 16px 16px 16px;
        border-radius: 5px 5px 5px 5px;
        border-style: solid;
        border-width: 1px 1px 1px 1px;
        border-color: #F1F5FB;
        width: 100%;
        max-width: 100%;
      }
      textarea {
        height: 120px;
      }
      ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #454545;
        opacity: 1;
      }
      ::-moz-placeholder { /* Firefox 19+ */
        color: #454545;
        opacity: 1;
      }
      :-ms-input-placeholder { /* IE 10+ */
        color: #454545;
        opacity: 1;
      }
      :-moz-placeholder { /* Firefox 18- */
        color: #454545;
        opacity: 1;
      }
    }
  }
  &.negative-margin{
    margin-top: -315px;
  }
}
</style>
